//site-header
$header-height: 315px;
.header-container {
    height: $header-height;
}
#site-header {
    min-height: $header-height;
    background-color: $primaryColor;
    &.sticky {
        position: fixed;
        width: 100%;
        margin-top: -258px;
        z-index: 2;
    }
    #site-title {
        background-color: $supportingColor1;
        color: $alternateTextColor;
        padding: 10px 20px;
        @extend .noMargin;
        margin-left: -($defaultGap*2);
        white-space: nowrap;
        letter-spacing: 4px;
        font-size: $fontLarge;
        position: absolute;
        font-weight: 300;
        @include breakpoint(mobileonly) {
            font-size: $fontMedium;
        }
        &:before {
            content: '';
            background-color: $supportingColor1;
            position: absolute;
            top: 0;
            left: -999px;
            width: 1000px;
            height: 48px;
        }
    }
    .designation {
        font-weight: 900;
        line-height: 1;
        font-size: $fontLarge + .3rem;
        color: $alternateTextColor;
        top: 120px;
        text-align: center;
        text-shadow: 3px 3px 10px $darkest;
        width: 300px;
        position: absolute;
        z-index: 1;
        @include breakpoint(mobileonly) {
            left: $defaultGap;
            font-size: $fontLarge - .3rem;
            width: 240px;
            top: 130px;
        }
    }
    .workDesk {
        width: 240px;
        height: 281px;
        position: absolute;
        background-size: contain;
        right: 50%;
        top: -25px;
        @include breakpoint(mobileonly) {
            display: none;
        }
    }
    .site-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 0;
        margin-left: -$defaultGap;
        @include breakpoint(mobileonly) {
            bottom: 10px;
        }
        li {
            float: left;
            &:not(:last-child) {
                margin-right: 20px;
                &:after {
                    content: '/';
                    font-size: 3.2rem;
                    font-weight: 300;
                    position: absolute;
                    top: -16px;
                    margin-left: 5px;
                }
            }
        }
        a {
            color: $darkest;
            font-size: $fontLarge;
            font-weight: 300;
            transition: .1s;
            line-height: 0;
            // text-shadow: 0 0 5px $lightColor;
            @include breakpoint(mobileonly) {
                font-size: $fontMedium;
            }
            &:focus {
                text-decoration: none;
            }
            &:hover {
                text-decoration: none;
                text-shadow: 0 0 5px $darkest;
            }
        }
    }
}