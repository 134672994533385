.mui-tabs__bar {
  &.mui-tabs__bar--justified {
    display: none;
    & > li:not(.mui--is-active) {
      @extend .hvr-underline-from-center;
      a {
        cursor: pointer;
        &:hover {
          color: $primaryColorDarker;
        }
      }
    }
  }
  & > li.mui--is-active {
    border-bottom-color: $primaryColorDarker;
    & > a {
      color: $primaryColorDarker;
    }
  }
}
