#pageOverlay {
  position: fixed;
  left: 0;
  background: $lightColor;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  &.up {
    top: 100%;
    animation: slideUp 0.5s forwards;
  }
  &.down {
    top: 0;
    animation: slideDown 0.3s forwards;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    text-align: right;
    background: $primaryColor;
    z-index: 2;
    line-height: 1;
    cursor: pointer;
    i {
      font-size: $fontLarge;
    }
  }
  .content-wrapper {
    // margin-top: 70px;
    h1.title {
      color: $primaryColorDarker;
    }

    iframe.CS-iframe {
      height: 100dvh;
      width: 100%;
      border: 0;
      border-bottom: 4px solid #535659;
    }
    iframe.framer-content {
      @include breakpoint(tabletandbelow) {
        margin: 0 -15px;
        width: 100vw;
      }
    }
    .mui-container {
      @include breakpoint(tabletandbelow) {
        max-width: 100%;
      }
    }

    .plaintext {
      figure {
        margin: 0;
        text-align: center;
        img {
          width: 100%;
          height: auto;
          &.auto {
            width: auto;
          }
        }
      }
    }

    [role="separator"] {
      border: 1px solid #CCC;
      margin: 48px 0;
      @include breakpoint(mobileonly) {
        margin: 32px 0;
      }
    }

    .sectors {
      li {
        border: 1px solid $primaryColorDark;
        color: $primaryColorDark;
        background: none;
        margin: 0 5px 0 0;
      }
    }
    .logo {
      height: 30px;
      margin: 18px 0;
      img {
        height: 100%;
      }
    }
    .grad {
      height: 250px;
      margin: 30px 0;
      overflow: hidden;
      padding: 20px 0;
      @include breakpoint(mobile375) {
        height: 220px;
      }
      @include breakpoint(mobile320) {
        height: 190px;
      }
      @include breakpoint(tablet) {
        height: 430px;
      }
      @include breakpoint(laptop) {
        height: 540px;
      }
      @include breakpoint(desktop) {
        height: 650px;
      }

      img.primary {
        width: 100%;
      }
    }
  }
  .tools-list {
    white-space: nowrap;
    li {
      @extend .clearfix;
      clear: both;
      padding: 5px 0;
      > div {
        float: left;
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
      }
      img {
        height: 30px;
      }
    }
  }
  .slider-wrapper {
    position: relative;
    // background: $primaryColorDark;
    padding-top: $defaultGap * 4;
    box-shadow: 0 0 20px;
    @include breakpoint(mobileonly) {
      margin: 0 -15px;
    }
    .slick-prev,
    .slick-next {
      top: -30px;
      width: 60px;
      height: 60px;
      z-index: 1;
      &:before {
        font-size: 60px;
      }
    }
    .slick-prev {
      left: 0;
      &:before {
        content: '\E314';
        font-family: 'Material Icons';
      }
    }
    .slick-next {
      right: 0;
      &:before {
        content: '\E315';
        font-family: 'Material Icons';
      }
    }
    .slick-list {
      z-index: 1;
    }
    .slick-dots {
      top: -50px;
      li button:before {
        color: $lightColor;
      }
      li.slick-active button:before {
        opacity: 1;
        color: $lightColor;
      }
    }
    .slide {
      text-align: center;
      position: relative;
      a {
        display: block;
        max-height: 500px;
        @include breakpoint(mobileonly) {
          max-height: 250px;
        }
        img {
          border-radius: 5px 5px 0 0;
          max-width: 90%;
          margin: 0 auto;
          box-shadow: 0 0 10px $darkColor;
        }
      }
    }
  }
}

@keyframes slideUp {
  100% {
    top: 0;
  }
}

@keyframes slideDown {
  100% {
    top: 120%;
  }
}
